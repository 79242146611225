import React, { useState, useEffect } from 'react';

function Vouchers() {
    const [vouchers, setVouchers] = useState([]);

    useEffect(() => {
        fetch('/api/vouchers/recent?limit=5')
            .then(response => response.json())
            .then(data => {
                console.debug(data);
                setVouchers(data);
            })
            .catch(error => console.error('Error fetching vouchers:', error));
    }, []);

    return (
        <div>
            <h1>Recent Vouchers</h1>
            <ul>
                {vouchers.map(voucher => (
                    <li key={voucher.id}>
                        Code: {voucher.code}, Status: {voucher.status},
						Date: {voucher.date ? new Date(voucher.date).toLocaleString() : 'N/A'}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Vouchers;
