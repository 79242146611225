// src/utils/formatUtils.js

/**
 * Formats a number (in cents) as a currency string in euros.
 * @param {number | undefined} amountInCents - The amount in cents to format.
 * @returns {string} - The formatted currency string or a default value if input is invalid.
 */
export const formatCurrency = (amountInCents) => {
    if (amountInCents == null || isNaN(amountInCents)) {
        return '€0.00';  // Return a default or placeholder string
    }
    const amountInEuros = amountInCents / 100;  // Convert cents to euros
    return `€${amountInEuros.toFixed(2)}`;
};
