// src/utils/dateUtils.js

export const getStandardDateRanges = () => {
    const now = new Date(); // get the current date and time
    const today = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
    const yesterday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - 1));
    const lastWeek = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - 7));
    const lastMonth = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() - 1, now.getUTCDate()));

    return {
        today: { startDate: today, endDate: new Date(today.getTime() + 86400000 - 1) }, // End date is just before midnight of the next day
        yesterday: { startDate: yesterday, endDate: new Date(yesterday.getTime() + 86400000 - 1) },
        lastWeek: { startDate: lastWeek, endDate: today },
        lastMonth: { startDate: lastMonth, endDate: today },
    };
};
