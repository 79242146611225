import React, { useState, useEffect } from 'react';

function RevolutBalance() {
    const [balance, setBalance] = useState(0);

    useEffect(() => {
        fetch('/api/revolut/balance')
            .then(response => response.json())
            .then(data => {
                console.debug(data);
                setBalance(data);
            })
            .catch(error => console.error('Error fetching Revolut balance:', error));
    }, []);

    return (
        <div>
            <h1>Revolut Balance</h1>
            <p>Current Balance: €{balance.toFixed(2)}</p>
        </div>
    );
}

export default RevolutBalance;
