import React from 'react';
import useDateRangeData from '../hooks/useDateRangeData';
import DateRangeButtons from './DateRangeButtons';
import { formatCurrency } from '../utils/formatUtils';

function FinancialSummary() {
    const initialDateRange = { startDate: new Date(), endDate: new Date() };
    const { data: summary, setDateRange } = useDateRangeData(initialDateRange, 'financial-summary/by-date');

    // Function to safely format percentage
    const formatPercentage = (value) => {
        if (value == null || isNaN(value)) {
            return '0.00%';  // Return a default or placeholder string
        }
        return `${value.toFixed(2)}%`;
    };

    return (
        <div>
            <h1>Financial Summary</h1>
            <DateRangeButtons onDateChange={setDateRange} />
            {summary ? (
                <div>
                    <p>Total Wager: {formatCurrency(summary.totalWager)}</p>
                    <p>Total Withdraw: {formatCurrency(summary.totalWithdraw)}</p>
                    <p>Total Profit: {formatCurrency(summary.totalProfit)}</p>
                    <p>Expected Total Profit: {formatCurrency(summary.expectedTotalProfit)}</p>
                    <p>Difference: <span style={{ color: summary.difference >= 0 ? 'green' : 'red' }}>{formatCurrency(summary.difference)}</span></p>
                    <p>Profit/Loss Percentage: {formatPercentage(summary.profitPercentage)}</p>
                    <p>Margin Over Expected: <span style={{ color: summary.marginOverExpected >= 0 ? 'green' : 'red' }}>{formatPercentage(summary.marginOverExpected)}</span></p>
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
}

export default FinancialSummary;
