// useDateRangeData.js
import { useState, useEffect } from 'react';

const useDateRangeData = (initialDateRange, endpoint) => {
    const [data, setData] = useState([]);
    const [dateRange, setDateRange] = useState({
        startDate: initialDateRange.startDate || new Date(),
        endDate: initialDateRange.endDate || new Date()
    });

    useEffect(() => {
        const fetchData = async () => {
            if (!dateRange.startDate || !dateRange.endDate) {
                console.error("StartDate or EndDate is undefined.");
                return; // Prevent further execution if dates are not valid
            }
            const formattedStartDate = dateRange.startDate.toISOString().split('T')[0];
            const formattedEndDate = dateRange.endDate.toISOString().split('T')[0];
            try {
                const response = await fetch(`/api/${endpoint}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`);
                const result = await response.json();
                setData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [dateRange, endpoint]);

    return { data, setDateRange };
};

export default useDateRangeData;
