import React from 'react';
import useDateRangeData from '../hooks/useDateRangeData';
import DateRangeButtons from './DateRangeButtons';

function Withdrawals() {
    const initialDateRange = { startDate: new Date(), endDate: new Date() };
    const { data: withdrawals, setDateRange } = useDateRangeData(initialDateRange, 'withdrawals/by-date');

    const updateDateRange = (newRange) => {
        if (!newRange.startDate || !newRange.endDate) {
            console.error("Invalid date range provided.");
            return;
        }
        setDateRange(newRange);
    };

    return (
        <div>
            <h1>Withdrawals</h1>
            <DateRangeButtons onDateChange={updateDateRange} />
            <ul>
                {withdrawals.map(withdrawal => (
                    <li key={withdrawal.id}>
                        Amount: {withdrawal.amount},
                        Date: {new Date(withdrawal.date).toLocaleString()}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Withdrawals;
