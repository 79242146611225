// src/components/DateRangeButtons.js
import React from 'react';
import { getStandardDateRanges } from '../utils/dateUtils';

const DateRangeButtons = ({ onDateChange }) => {
    const ranges = getStandardDateRanges();

    return (
        <>
            <button onClick={() => onDateChange(ranges.today)}>Today</button>
            <button onClick={() => onDateChange(ranges.yesterday)}>Yesterday</button>
            <button onClick={() => onDateChange(ranges.lastWeek)}>Last Week</button>
            <button onClick={() => onDateChange(ranges.lastMonth)}>Last Month</button>
        </>
    );
};

export default DateRangeButtons;
