import React from 'react';
import './App.css';
import Withdrawals from './components/Withdrawals';
import Vouchers from './components/Vouchers';
import RevolutBalance from './components/RevolutBalance';
import FinancialSummary from './components/FinancialSummary';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to the Financial Dashboard</h1>
      </header>
      <div className="section">
        <FinancialSummary />
      </div>
      <div className="section">
        <RevolutBalance />
      </div>
      <div className="section">
        <Withdrawals />
      </div>
      <div className="section">
        <Vouchers />
      </div>
    </div>
  );
}

export default App;
